<template>
  <div>
    <bread-crumb :crumbData="['登录账号', '编辑']" :chooseCrumbData="2" @close="onClose"></bread-crumb>

    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm"
      style="padding: 50px; width: 75%">
      <el-form-item label="用户名：">
        <span>{{ ruleForm.userName }}</span>
      </el-form-item>
      <el-form-item label="绑定手机：">
        <span>{{ ruleForm.phone }}</span>
      </el-form-item>
      <el-form-item label="真实姓名：" prop="realName">
        <el-input v-model="ruleForm.realName"></el-input>
      </el-form-item>
      <el-form-item label="邮箱：">
        <el-input v-model="ruleForm.email" placeholder="@"></el-input>
      </el-form-item>
      <el-form-item label="所在门店：" prop="store">
        <el-select v-model="ruleForm.store">
          <el-option v-for="item in stores" :key="item.id" :label="item.shop_name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="角色:">
        <el-checkbox-group v-model="ruleForm.role">
          <el-checkbox v-for="item in roles" :key="item" :label="item" name="role"></el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="推广策略：">
        <el-select v-model="ruleForm.expand_strategy" clearable placeholder="请选择">
          <el-option label="支持金币支付" value="支持金币支付"></el-option>
          <el-option label="禁用金币支付" value="禁用金币支付"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否启用：" prop="isEnable">
        <el-switch v-model="ruleForm.isEnable" :active-value="1" :inactive-value="0" active-color="#13ce66"
          inactive-color="#ff4949">
        </el-switch>
      </el-form-item>
      <el-form-item label="是否锁定：" prop="isLock">
        <el-switch v-model="ruleForm.isLock" :active-value="1" :inactive-value="0" active-color="#13ce66"
          inactive-color="#ff4949">
        </el-switch>
      </el-form-item>
      <el-row>
        <el-col :span="10">
          <el-form-item label="锁定日期：">
            <span>{{ lockDate }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="登录失败次数：" required>
            <span>{{ login_error_sum }}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item label="最后登录日期：">
            <span>{{ loginDate }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="最后登录IP：">
            <span>{{ login_ip }}</span>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  props: {
    rowData: { type: Object, require: true },
  },
  data() {
    return {
      stores: [],
      roles: [
        "财务2",
        "财务1",
        "区域代理商",
        "财务",
        "自提管理员",
        "司机",
        "办公室",
        "客服",
        "副库管",
        "库管",
        "业务经理",
        "总经理",
        "推广员",
        "送货员",
        "店长",
        "管理员",
      ],
      ruleForm: {
        commercial_id: localStorage.getItem("commercial_id") ||
          sessionStorage.getItem("commercial_id"),
        id: "",
        userName: "",
        phone: "",
        realName: "",
        email: "",
        store: "",
        role: "",
        expand_strategy: "",
        isEnable: 0,
        isLock: 0,
      },
      rules: {
        userName: [{ required: true, message: "必填字段", trigger: "blur" }],
        realName: [{ required: true, message: "必填字段", trigger: "blur" }],
        store: [{ required: true, message: "必选字段", trigger: "change" }],
        role: [{ required: true, message: "必选字段", trigger: "change" }],
        isEnable: [{ required: true, message: "必选字段", trigger: "change" }],
        isLock: [{ required: true, message: "必选字段", trigger: "change" }],
      },
      login_ip: "",
      lockDate: "",
      loginDate: "",
      login_error_sum: "",
      commercial_id: "",
    };
  },
  async mounted() {
    this.commercial_id =
      localStorage.getItem("commercial_id") ||
      sessionStorage.getItem("commercial_id");
    const res = await this.$http.post("/system/shop/lst", {
      commercial_id: this.commercial_id,
    });
    this.stores = res.data.data;
    if (this.rowData) {
      const data = this.rowData;
      this.ruleForm = data;
      this.ruleForm.role = data.role.split(",");
    }
    console.log(this.ruleForm.role);
  },
  computed: {},
  // created() {
  //   console.log(this.rowData);
  // },
  methods: {
    onClose() {
      this.$emit("onClose", 0);
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // this.ruleForm.id = this.compileId;
          console.log(this.rowData);
          this.ruleForm.role = this.ruleForm.role.join(",");
          this.ruleForm.store = this.ruleForm.store.id;
          // console.log(this.$qs.stringify(this.ruleForm));
          this.$http
            .post("/system/employee/save", (this.ruleForm))
            .then(() => {
              this.onClose(0);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style scoped></style>
