<template>
  <div>
    <bread-crumb
      :crumbData="['登录账号', '新增']"
      :chooseCrumbData="2"
      @close="onClose"
    ></bread-crumb>

    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
      style="padding: 50px; width: 75%"
    >
      <el-form-item label="用户名：" prop="userName">
        <el-input v-model="ruleForm.userName"></el-input>
      </el-form-item>
      <el-form-item label="真实姓名：" prop="realName">
        <el-input v-model="ruleForm.realName"></el-input>
      </el-form-item>
      <el-form-item label="邮箱：">
        <el-input v-model="ruleForm.email" placeholder="@"></el-input>
      </el-form-item>
      <el-form-item label="手机：" prop="phone">
        <el-input v-model="ruleForm.phone"></el-input>
      </el-form-item>
      <el-form-item label="初始密码：" prop="initialPassword">
        <el-input v-model="ruleForm.initialPassword" show-password></el-input>
      </el-form-item>
      <el-form-item label="所在门店：" prop="store">
        <el-select v-model="ruleForm.store">
          <el-option
            v-for="item in stores"
            :key="item.id"
            :label="item.shop_name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="角色:">
        <el-checkbox-group v-model="ruleForm.role">
          <el-checkbox
            v-for="item in roles"
            :key="item"
            :label="item"
            name="role"
          ></el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >提交</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      stores: [],
      roles: [
        "财务2",
        "财务1",
        "区域代理商",
        "财务",
        "自提管理员",
        "司机",
        "办公室",
        "客服",
        "副库管",
        "库管",
        "业务经理",
        "总经理",
        "推广员",
        "送货员",
        "店长",
        "管理员",
      ],
      ruleForm: {
        userName: "",
        realName: "",
        email: "",
        initialPassword: "",
        store: "",
        role: [],
        phone: "",
        commercial_id: "",
      },
      rules: {
        userName: [{ required: true, message: "必填字段", trigger: "blur" }],
        realName: [{ required: true, message: "必填字段", trigger: "blur" }],
        initialPassword: [
          { required: true, message: "必填字段", trigger: "blur" },
        ],
        store: [{ required: true, message: "必填字段", trigger: "change" }],
      },
    };
  },
  mounted() {
    this.ruleForm.commercial_id =
      localStorage.getItem("commercial_id") ||
      sessionStorage.getItem("commercial_id");
    this.$http
      .post("/system/shop/lst", {
        commercial_id: this.ruleForm.commercial_id,
      })
      .then((res) => {
        this.stores = res.data.data;
      });
  },
  computed: {},
  methods: {
    onClose(data) {
      this.$emit("close", data);
    },
    submitForm(formName) {
      this.ruleForm.role = this.ruleForm.role.join(",");
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$http
            .post("/system/employee/save", this.ruleForm)
            .then((res) => {
              if (res.data.code == 500) {
                this.$message.error(res.data.msg);
              }
              this.ruleForm = {};
              this.onClose(0);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style scoped></style>
