<template>
  <div>
    <div v-if="isShow == 0">
      <bread-crumb :crumbData="['登录账号']" :chooseCrumbData="1" reloadComponent="staffManage"></bread-crumb>
      <search-case :searchData="searchData" @inquireBtnClick="inquireBtnClick"></search-case>

      <div style="
          padding: 10px 20px;
          border-bottom: 1px solid #eee;
          display: flex;
          align-items: center;
        ">
        <div style="margin-right: 5px">
          <el-button type="primary" size="mini" @click="isShow = 1">
            <div style="display: flex; align-items: center; font-size: 14px">
              <span class="spriteChart_add"></span>
              <span style="margin-left: 5px">新增账号</span>
            </div>
          </el-button>
        </div>
        <div style="margin-right: 5px">
          <el-button type="danger" size="mini" @click="batchRemoveClick">
            <div style="display: flex; align-items: center; font-size: 14px">
              <span class="spriteChart_delete"></span>
              <span style="margin-left: 5px">批量删除</span>
            </div>
          </el-button>
        </div>
      </div>

      <div style="padding: 20px">
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" border
          @selection-change="handleSelectionChange" style="width: 100%">
          <el-table-column type="selection" width="44"> </el-table-column>
          <el-table-column prop="id" sortable label="ID" width="60%"></el-table-column>
          <el-table-column prop="create_time" sortable label="创建日期" width="120%"></el-table-column>
          <el-table-column prop="userName" label="用户名"></el-table-column>
          <el-table-column prop="realName" label="真实姓名"></el-table-column>
          <el-table-column label="绑定账号">
            <template slot-scope="scope">
              <el-tag type="success">
                {{ scope.row.isBindAccount ? "已绑定" : "未绑定" }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="store.shop_name" label="所在门店"></el-table-column>
          <el-table-column label="是否启用">
            <template slot-scope="scope">
              <el-tag type="success">
                {{ scope.row.isEnable ? "是" : "否" }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column label="是否锁定">
            <template slot-scope="scope">
              <el-tag type="success">
                {{ scope.row.isLock ? "是" : "否" }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="location" label="所在地"></el-table-column>
          <el-table-column prop="role" label="角色"></el-table-column>
          <el-table-column label="密码重置">
            <template slot-scope="scope">
              <el-button type="success" @click="resetPasswords(scope.row)">重置密码</el-button>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type="text" size="mini" @click="handleEdit(scope.row)">
                <div style="display: flex; align-items: center; font-size: 14px">
                  <span class="table_spriteChart_amend"></span>
                </div>
              </el-button>
              <span style="margin: 0 10px"></span>
              <el-button type="text" size="mini" @click="handleDelete(scope.row.id)">
                <div style="display: flex; align-items: center; font-size: 14px">
                  <span class="table_spriteChart_delete"></span>
                </div>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="tableBottom">
          <span style="">显示第&nbsp;{{
            tableData.length > 0
            ? (currentPage - 1) * currentLength + 1
            : "0"
          }}&nbsp;至&nbsp;{{
  (currentPage - 1) * currentLength + tableData.length
}}&nbsp;项结果，共&nbsp;{{ tabletotalNum }}&nbsp;项</span>

          <div style="
              display: flex;
              justify-content: flex-end;
              align-items: center;
            ">
            <el-button @click="homePage">首页</el-button>
            <el-button @click="upPage">上页</el-button>
            <el-pagination background layout=" pager,slot" @current-change="currentChange" :total="tabletotalNum"
              :current-page="page"></el-pagination>
            <el-button @click="nextPage">下页</el-button>
            <el-button @click="endPage">末页</el-button>
          </div>
        </div>
      </div>
    </div>

    <account-add v-if="isShow == 1" @close="onClose"></account-add>
    <compile v-if="isShow == 2" @onClose="onClose" :rowData="rowData"></compile>
  </div>
</template>
<script>
import accountAdd from "./childrens/account-add.vue";
import compile from "./childrens/compile.vue";
export default {
  components: {
    accountAdd,
    compile,
  },
  data() {
    return {
      isShow: 0,

      searchData: {
        date: true,
        isInquireValue: true,
        inquirePlaceholder: "输入要查询的内容",
        isInquireBtn: true,
        isThirdSelect: true,
        thirdSelectOptiones: ["男", "女", "保密"],
      },

      tableData: [],
      multipleSelection: [],
      isResult: true,
      tabletotalNum: 0,
      currentPage: 1,
      currentLength: 10,
      perPage: 0,
      commercialId: "",
      rowData: {},
    };
  },
  mounted() {
    this.commercialId =
      localStorage.getItem("commercial_id") ||
      sessionStorage.getItem("commercial_id");
    this.inquireList();
  },
  computed: {},
  watch: {
    tableData(newVal) {
      if (newVal.length == 0) {
        this.isResult = false;
      } else {
        this.isResult = true;
      }
    },
    page: {
      handler() {
        this.inquireList();
      },
    },
  },
  methods: {
    // 查询
    inquireList() {
      this.$http
        .post(
          "/system/Employee/lst",
          this.$qs.stringify({
            currentPage: this.currentPage,
            currentLength: this.currentLength,
            commercial_id: this.commercialId,
          })
        )
        .then((res) => {
          this.tableData = res.data.data;
          this.tabletotalNum = res.data.count;
        });
    },
    // 批量删除
    batchRemoveClick() {
      if (this.multipleSelection != "") {
        this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            const idArr = this.multipleSelection.map((item) => {
              return item.id;
            });
            this.$http
              .post("/system/employee/bacthDel", {
                idArr,
              })
              .then((res) => {
                if (res.data.status == "success") {
                  this.$message({ type: "success", message: res.data.msg });
                  this.inquireList();
                }
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
    },
    // 多选
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 查询传值
    inquireBtnClick(inquireVal) {
      this.$http
        .post(
          "/system/employee/search",
          this.$qs.stringify({
            firstTime: inquireVal.dateRange[0],
            lastTime: inquireVal.dateRange[1],
            search: inquireVal.inquireValue,
            gender: inquireVal.thirdSelect,
            commercial_id: this.commercialId,
          })
        )
        .then((res) => {
          this.tableData = res.data.data;
          this.tabletotalNum = res.data.count;
        });
    },
    // 修改单条数据 -- 传值id给compile.vue页面
    handleEdit(row) {
      this.rowData = row;
      this.isShow = 2;
    },
    // 删除单条数据
    handleDelete(id) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http
            .post("/system/Employee/del", {
              id,
            })
            .then((res) => {
              if (res.data.status == "success") {
                this.$message({ type: "success", message: res.data.msg });
                this.inquireList();
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    resetPasswords() { },
    currentChange(page) {
      this.page = page;
    },
    homePage() {
      this.page = 1;
    },
    upPage() {
      if (this.page == 1) return;
      this.page -= 1;
    },
    nextPage() {
      if (this.page >= Math.ceil(this.tableFinshNum / this.length)) return;
      this.page += 1;
    },
    endPage() {
      this.page = Math.ceil(this.tableFinshNum / this.length);
    },
    onClose(data) {
      this.isShow = data;
      this.inquireList();
    },
  },
};
</script>
<style scoped lang="less">
.tableBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
}

/deep/ .cell {
  padding: 0 !important;
}

.iconImg_delete {
  width: 16px;
  height: 16px;
  border: 1px solid;
  background-position: -1px -85px;
  background-color: rgba(0, 0, 0, 0.1);
}
</style>
